import styled from '@emotion/styled'
import { isSameDay, parseISO, isToday as checkIsToday, getDate, getMonth, getYear } from 'date-fns'
import { useRecoilValue } from 'recoil'

import { DAYS_OF_WEEK } from '@constants/calendar'
import { colors } from '@styles/colors'
import Text from '@common/Text'
import Day from '@components/schedule/calendar/Day'
import { padToTwoDigits } from '@utils/numberFormatting'
import { selectedDateState } from '@atoms/selectedDate'

interface CalendarGridProps {
  days: Array<{ date: Date; isCurrentMonth: boolean }>
  koreaSpecialDays?: {
    id: number
    name: string
    specialDayAt: string
    isHoliday: boolean
  }[]
  dailyReservations: any
  dayOffs: { id: number; dayOffAt: string }[]
}

const CalendarGrid = ({
  days,
  koreaSpecialDays,
  dailyReservations,
  dayOffs,
}: CalendarGridProps) => {
  const selectedDate = useRecoilValue(selectedDateState)

  const getHolidayName = (day: Date) => {
    const specialDay = koreaSpecialDays?.find(
      (specialDay) => specialDay.isHoliday && isSameDay(parseISO(specialDay.specialDayAt), day),
    )
    return specialDay ? specialDay.name : null
  }

  const getPetCount = (day: Date, type: string) => {
    const dailyReservation = dailyReservations?.find((res: any) =>
      isSameDay(new Date(res.reservedAt), day),
    )
    return dailyReservation ? dailyReservation[type] : 0
  }

  const isSelected = (day: Date): boolean => {
    return (
      selectedDate &&
      selectedDate.day === padToTwoDigits(getDate(day)) &&
      selectedDate.month === padToTwoDigits(getMonth(day) + 1) &&
      selectedDate.year === getYear(day).toString()
    )
  }

  const isToday = (day: Date) => checkIsToday(day)

  const isDayOff = (day: Date) =>
    dayOffs?.some((dayOff) => isSameDay(parseISO(dayOff.dayOffAt), day))

  return (
    <Container>
      {DAYS_OF_WEEK.map((day) => (
        <Dow key={day}>
          <Text
            typography='16_Sb'
            color={day === '일' ? 'red600' : day === '토' ? 'blue600' : 'gray400'}
          >
            {day}
          </Text>
        </Dow>
      ))}

      {days.map(({ date: day, isCurrentMonth }, idx) => (
        <Day
          key={idx}
          day={day}
          isCurrentMonth={isCurrentMonth}
          isSelected={isSelected}
          isDayOff={isDayOff}
          isToday={isToday}
          getHolidayName={getHolidayName}
          getPetCount={getPetCount}
        />
      ))}
    </Container>
  )
}

export default CalendarGrid

const Container = styled.div`
  flex-grow: 1;
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 38px repeat(6, 1fr);
  border: 1px solid ${colors.gray200};
  border-radius: 10px;
  overflow: hidden;
`

const Dow = styled.div`
  padding: 10px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.gray200};
`
