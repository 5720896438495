import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import Text from '@common/Text'
import React from 'react'

interface TableProps {
  head: string[]
  children: React.ReactNode
  height?: number
}

const Table = ({ head, children, height = 0 }: TableProps) => {
  return (
    <>
      <TableContainer css={TableStyle} height={height}>
        <thead>
          <tr>
            {head.map((item, index) => (
              <th key={index}>
                <Text typography='14_Rg' color='gray500'>
                  {item}
                </Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </TableContainer>
    </>
  )
}

const TableContainer = styled.table<{ height: number }>`
  width: 100%;
  ${({ height }) => height && `${height}px;`}
`

const TableStyle = css`
  thead {
    border-top: 1px solid ${colors.gray100};
    border-bottom: 1px solid ${colors.gray100};

    tr {
      text-align: left;
    }

    th {
      padding: 11px 8px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid ${colors.gray100};
      cursor: pointer;

      &:hover {
        background-color: ${colors.gray100};
      }
    }

    td {
      max-width: 100px;
      font-size: 14px;
      font-weight: 400;
      padding: 19px 8px;
      color: ${colors.gray600};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td:nth-of-type(2) {
      white-space: none;
      overflow: visible;
      text-overflow: unset;
    }
  }
`

export default Table
