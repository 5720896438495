import { useMutation } from '@tanstack/react-query'

import { updateCustomerActive } from '@apis/customer/customer'

interface useActiveProps {
  onSuccess: () => void
  onError: () => void
  kindergardenId: number
}

export const useActive = ({ onSuccess, onError, kindergardenId }: useActiveProps) => {
  return useMutation({
    mutationFn: (id: number) =>
      updateCustomerActive({
        pet_kindergarden_id: kindergardenId,
        customer_id: id,
      }),
    onSuccess: () => {
      onSuccess()
    },
    onError: () => {
      onError()
    },
  })
}
