const CancelIcon = () => {
  return (
    <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 2.00098L2 8.00098'
        stroke='#6E7A93'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 8L2 2' stroke='#6E7A93' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default CancelIcon
