import styled from '@emotion/styled'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRef, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { useRecoilValue } from 'recoil'

import { toggleIsAttended, deleteReservation } from '@apis/calendar/calendar'
import { colors } from '@styles/colors'
import { Reservation, SelectedDate } from '@/types/calendar'
import nonAttendance from '@assets/images/non-attendance.svg'
import attendance from '@assets/images/attendance.svg'
import iconMore from '@assets/icon_more2.svg'
import Text from '@common/Text'
import Flex from '@common/Flex'
import Button from '@common/Button'
import Modal from '@common/Modal'
import { selectedDateState } from '@atoms/selectedDate'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

interface ReservationItemProps {
  reservation: Reservation
  type: string
  openMenuId: number | null
  handleToggleMenu: (id: number | null) => void
  handleShowToast: (message: string) => void
}

const ReservationItem = ({
  reservation,
  type,
  openMenuId,
  handleToggleMenu,
  handleShowToast,
}: ReservationItemProps) => {
  const queryClient = useQueryClient()
  const petKindergardenId = useRecoilValue(petKindergardenIdState)
  const selectedDate = useRecoilValue(selectedDateState)
  const menuRef = useRef<HTMLDivElement>(null)
  const [showCancleModal, setShowCancleModal] = useState(false)
  const [reservationForDelete, setReservationForDelete] = useState<Reservation | null>(null)

  const { mutate: toggleIsAttendedMutation } = useMutation({
    mutationFn: (reservationId: number) => {
      return toggleIsAttended(petKindergardenId as number, reservationId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['calendar'],
      })
    },
  })

  const { mutate: deleteReservationMutation } = useMutation({
    mutationFn: (reservationId: number) => {
      return deleteReservation(petKindergardenId as number, reservationId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['calendar'],
      })
      handleShowToast('예약이 취소되었습니다')
    },
  })

  const isFutureDate = (date: SelectedDate) => {
    const today = new Date()
    const selected = new Date(parseInt(date.year), parseInt(date.month) - 1, parseInt(date.day))
    return selected > today
  }

  const isDateWithinRange = (today: Date, startDate: string, endDate: string) => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    return today >= start && today <= end
  }

  const today = new Date()
  const isHotelWithCurrentDate =
    type === 'hotel' &&
    reservation.reservedAt !== undefined &&
    reservation.endAt !== undefined &&
    isDateWithinRange(today, reservation.reservedAt, reservation.endAt)

  const showAttendanceButton =
    !isFutureDate(selectedDate) || (isHotelWithCurrentDate && reservation.isAttended)

  const isMenuOpen = openMenuId === reservation.id

  const handleCancelAttendance = () => {
    toggleIsAttendedMutation(reservation.id)
    handleToggleMenu(reservation.id)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      handleToggleMenu(null)
    }
  }

  const openCancelModal = (reservation: Reservation) => {
    setShowCancleModal(true)
    setReservationForDelete(reservation)
  }

  const handleCancelReservation = () => {
    deleteReservationMutation(reservationForDelete?.id as number)
    setShowCancleModal(false)
  }

  const calculateDuration = (startTime: string | undefined, endTime: string | undefined) => {
    const start = new Date(`1970-01-01T${startTime}`).getHours()
    const end = new Date(`1970-01-01T${endTime}`).getHours()
    const diff = end - start
    return `${diff}시간`
  }

  const calculateStayDuration = (startDate: string | undefined, endDate: string | undefined) => {
    if (!startDate || !endDate) {
      return ''
    }
    const start = new Date(startDate)
    const end = new Date(endDate)
    const diff = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
    return `${diff}박`
  }

  const formatTime = (time: string | undefined) => {
    if (!time) return ''
    const date = new Date(`1970-01-01T${time}`)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMenuOpen])

  return (
    <IndividualItem justify='space-between'>
      <Flex direction='column' gap='4px' justify='center'>
        <Flex gap='4px' align='center'>
          <Text typography='16_Sb' color='gray600'>
            {reservation.customerPet.name}
          </Text>
          <Text typography='13_Rg' color='gray500'>
            {reservation.customer.name}
          </Text>
        </Flex>

        {type === 'time' && (
          <Text typography='12_Rg' color='gray500'>
            {formatTime(reservation.reservedTime)} - {formatTime(reservation.endTime)} ・{' '}
            {calculateDuration(reservation.reservedTime, reservation.endTime)}
          </Text>
        )}

        {type === 'hotel' && (
          <Text typography='12_Rg' color='gray500'>
            {reservation.reservedAt} - {reservation.endAt} ・{' '}
            {calculateStayDuration(reservation.reservedAt, reservation.endAt)}
          </Text>
        )}
      </Flex>

      <Flex gap='12px' justify='center' align='center'>
        {showAttendanceButton &&
          (reservation.isAttended ? (
            <img src={attendance} alt='출석' />
          ) : (
            <Button
              color='line'
              css={{
                width: 76,
                gap: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => toggleIsAttendedMutation(reservation.id)}
            >
              <img src={nonAttendance} alt='출석 하기' />
              출석
            </Button>
          ))}
        <img src={iconMore} alt='더 보기' onClick={() => handleToggleMenu(reservation.id)} />

        {isMenuOpen && (
          <Menu ref={menuRef}>
            {reservation.isAttended && (
              <MenuItem onClick={handleCancelAttendance}>
                <Text typography='14_Md' color='gray500'>
                  등원 취소
                </Text>
              </MenuItem>
            )}
            <MenuItem onClick={() => openCancelModal(reservation)}>
              <Text typography='14_Md' color='gray500'>
                예약 취소
              </Text>
            </MenuItem>
          </Menu>
        )}

        <Modal
          showModal={showCancleModal}
          setShowModal={setShowCancleModal}
          rightButtonLabel='예약 취소'
          title='예약 취소'
          description='아래 예약을 취소합니다.'
          rightButtonOnClick={() => handleCancelReservation()}
        >
          <Flex direction='column' css={modalCss} gap='4px'>
            <Text typography='16_Sb' color='gray600'>
              {reservationForDelete?.customerPet.name}
            </Text>
            <Text typography='12_Rg' color='gray500'>
              {reservationForDelete?.customer.name}
            </Text>

            <Text typography='12_Rg' color='gray500'>
              {type === 'time' && (
                <>
                  시간 이용권 • {reservationForDelete?.reservedTime} -{' '}
                  {reservationForDelete?.endTime}
                </>
              )}

              {type === 'allDay' && (
                <>
                  종일 이용권 • {selectedDate.year}.{selectedDate.month}.{selectedDate.day}
                </>
              )}

              {type === 'hotel' && (
                <>
                  호텔 이용권 • {reservationForDelete?.reservedAt} - {reservationForDelete?.endAt}
                </>
              )}
            </Text>
          </Flex>
        </Modal>
      </Flex>
    </IndividualItem>
  )
}

export default ReservationItem

const modalCss = css`
  gap: 4px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${colors.gray200};
`

const IndividualItem = styled(Flex)`
  position: relative;
  border-bottom: 1px solid ${colors.gray100};
  height: 72px;
`

const Menu = styled.div`
  position: absolute;
  background: white;
  box-shadow: 0px 4px 15px 0px #272d3a26;
  border-radius: 4px;
  right: 0;
  top: 40px;
  z-index: 10;
`

const MenuItem = styled.div`
  padding: 19px 44.5px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.gray100};
  }
`
