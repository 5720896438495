import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRef } from 'react'
import { useRecoilState } from 'recoil'
import DaumPostcodeEmbed from 'react-daum-postcode'

import { addressState } from '@atoms/address'
import Button from '@common/Button'
import Dimmed from '@common/Dimmed'
import Flex from '@common/Flex'

interface AddressProps {
  setAddressValue: React.Dispatch<
    React.SetStateAction<{
      roadAddress: string
      abbrAddress: string
      shortAddress: string[]
    }>
  >
}

const Address = ({ setAddressValue }: AddressProps) => {
  const [show, setShow] = useRecoilState(addressState)
  const modalRef = useRef<HTMLDivElement>(null)

  const handleComplete = (data: any) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }

    setAddressValue({
      roadAddress: fullAddress,
      abbrAddress: data.jibunAddress || data.autoJibunAddress,
      shortAddress: fullAddress.split(' ').slice(0, 3),
    })

    setShow(false)
  }

  const onClose = () => {
    setShow(false)
  }

  const modalOutSideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current === e.target) setShow(false)
  }

  return (
    <>
      {show && (
        <Dimmed modalRef={modalRef} onClick={modalOutSideClick}>
          <AddressContainer direction='column'>
            <DaumPostcodeEmbed
              onComplete={handleComplete}
              autoClose
              style={{
                width: '600px',
                height: '700px',
              }}
            />
            <Flex align='center'>
              <Button color='normal' size='large' onClick={onClose} css={buttonStyle}>
                취소
              </Button>
            </Flex>
          </AddressContainer>
        </Dimmed>
      )}
    </>
  )
}

const AddressContainer = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
`

const buttonStyle = css`
  width: 100%;
  margin: 16px 40px;
`

export default Address
