import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import Text from '@common/Text'
import Flex from '@common/Flex'
import ticketTime from '@assets/images/ticket-time.svg'
import ticketAllDay from '@assets/images/ticket-allday.svg'
import ticketHotel from '@assets/images/ticket-hotel.svg'

const EmptyTicket = () => {
  return (
    <>
      <ImgBox>
        <Img src={ticketTime} alt='시간 이미지' />
        <Img src={ticketAllDay} alt='종일 이미지' />
        <Img src={ticketHotel} alt='호텔 이미지' />
      </ImgBox>
      <Text typography='24_Sb' color='gray600'>
        이용권이 없다멍...
      </Text>
      <Text typography='14_Rg' color='gray500'>
        시간, 종일, 호텔 이용권을 자유롭게 만들 수 있어요
      </Text>
    </>
  )
}

export default EmptyTicket

const ImgBox = styled(Flex)`
  gap: 17px;
`

const Img = styled.img`
  border: 1px solid ${colors.gray200};
  border-radius: 10px;
`
