import { keyframes } from '@emotion/react'
import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import Text from '@common/Text'

interface ToastProps {
  message: string
  duration?: number
  onClose: () => void
}

const NewToast = ({ message, duration = 2000, onClose }: ToastProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose()
    }, duration)

    return () => clearTimeout(timer)
  }, [])

  return createPortal(
    <ToastMessage duration={duration}>
      <Text typography='14_Md' color='white'>
        {message}
      </Text>
    </ToastMessage>,
    document.getElementById('toast') as HTMLElement,
  )
}

export default NewToast

const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`

const ToastMessage = styled.div<{ duration: number }>`
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  padding: 18px 40px;
  background-color: ${colors.gray600};
  border-radius: 27.5px;
  text-align: center;
  animation: ${fadeInOut} ${({ duration }) => duration}ms ease-in-out;
`
