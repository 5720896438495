import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { colors } from '@styles/colors'
import { Ticket, ReservationRequest, Customer } from '@/types/calendar'
import { formatDate } from '@utils/generateDate'
import { getDayOff } from '@apis/calendar/calendar'
import Spacing from '@common/Spacing'
import Text from '@common/Text'
import Flex from '@common/Flex'
import CustomSelect from '@common/CustomSelect'
import { selectedDateState } from '@atoms/selectedDate'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

interface SelectHotelTicketProps {
  tickets: { hotel: Ticket[] }
  selectedCustomer: Customer | null
  selectedStay: string
  setSelectedStay: (selectedValue: string) => void
  setFinalData: (reservation: ReservationRequest) => void
}

const ONE_DAY_MS = 24 * 60 * 60 * 1000

const SelectHotelTicket = ({
  tickets,
  selectedCustomer,
  selectedStay,
  setSelectedStay,
  setFinalData,
}: SelectHotelTicketProps) => {
  const petKindergardenId = useRecoilValue(petKindergardenIdState)

  const selectedDate = useRecoilValue(selectedDateState)
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)

  const getSortedTickets = (tickets: Ticket[]) =>
    tickets.sort(
      (a: Ticket, b: Ticket) => new Date(a.expiredAt).getTime() - new Date(b.expiredAt).getTime(),
    )

  const { data } = useQuery({
    queryKey: ['getDayOff', petKindergardenId],
    queryFn: () => getDayOff(petKindergardenId as number, endDate, startDate),
    enabled: !!petKindergardenId && !!startDate && !!endDate,
  })

  const dayOffDates = data?.dayOffs

  const reservationDates = useMemo(() => {
    const sortedTickets = getSortedTickets(tickets.hotel)

    let totalUnusedCount = 0
    const selectedDateTime = new Date(
      `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`,
    )

    for (const ticket of sortedTickets) {
      const ticketExpiryDate = new Date(ticket.expiredAt)

      const remainingDaysUntilExpiry = Math.ceil(
        (ticketExpiryDate.getTime() - selectedDateTime.getTime()) / ONE_DAY_MS,
      )
      const availableNights = Math.min(ticket.unusedCount, remainingDaysUntilExpiry)

      totalUnusedCount += availableNights

      if (remainingDaysUntilExpiry <= 0) break
    }

    const start = new Date(selectedDateTime.getTime() + ONE_DAY_MS)
    const startDateString = `${start.getFullYear()}-${(start.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${start.getDate().toString().padStart(2, '0')}`

    const end = new Date(selectedDateTime.getTime() + totalUnusedCount * ONE_DAY_MS)
    const endDateString = `${end.getFullYear()}-${(end.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${end.getDate().toString().padStart(2, '0')}`

    return { startDateString, endDateString }
  }, [selectedDate, tickets])

  useEffect(() => {
    setStartDate(reservationDates.startDateString)
    setEndDate(reservationDates.endDateString)
  }, [reservationDates])

  const getStayOptions = () => {
    const sortedTickets = getSortedTickets(tickets.hotel)

    const validTickets = sortedTickets.filter((ticket) => {
      const ticketExpiryDate = new Date(ticket.expiredAt)
      const selectedDateTime = new Date(
        `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`,
      )
      return ticketExpiryDate >= selectedDateTime
    })

    let totalUnusedCount = 0

    for (const ticket of validTickets) {
      const ticketExpiryDate = new Date(ticket.expiredAt)
      const selectedDateTime = new Date(
        `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`,
      )

      const remainingDaysUntilExpiry = Math.ceil(
        (ticketExpiryDate.getTime() - selectedDateTime.getTime()) / ONE_DAY_MS,
      )
      const availableNights = Math.min(ticket.unusedCount, remainingDaysUntilExpiry)

      totalUnusedCount += availableNights
    }

    const earliestDayOff = dayOffDates
      ?.map((dayOff: { dayOffAt: string }) => new Date(dayOff.dayOffAt))
      .sort((a: Date, b: Date) => a.getTime() - b.getTime())[0]

    return Array.from({ length: totalUnusedCount }, (_, i) => {
      const stayDuration = i + 1
      const endDate = new Date(
        new Date(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`).getTime() +
          stayDuration * ONE_DAY_MS,
      )
      const endLabel = formatDate(endDate.toISOString())

      let labelSuffix = ''
      if (earliestDayOff && endDate.getTime() >= earliestDayOff.getTime()) {
        labelSuffix =
          endDate.getTime() === earliestDayOff.getTime() ? ' / 휴무일 선택불가' : ' / 선택불가'
      }

      return {
        value: stayDuration.toString(),
        label: `${stayDuration}박 - ${endLabel}까지${labelSuffix}`,
        disabled: earliestDayOff ? endDate.getTime() >= earliestDayOff.getTime() : false,
      }
    })
  }

  const selectStay = (selectedValue: any) => {
    setSelectedStay(selectedValue)

    let stayToUse = parseInt(selectedValue, 10)
    if (!selectedCustomer) return

    const usedTicketCounts: { [key: number]: number }[] = []

    const sortedTickets = getSortedTickets(
      tickets.hotel.filter((ticket) => {
        const ticketExpiryDate = new Date(ticket.expiredAt)
        const selectedDateTime = new Date(
          `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`,
        )
        return ticketExpiryDate >= selectedDateTime
      }),
    )

    let currentDate = new Date(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`)

    for (const ticket of sortedTickets) {
      if (stayToUse <= 0) break

      const ticketExpiryDate = new Date(ticket.expiredAt)

      let daysToUse = 0
      const daysUntilExpiry =
        Math.floor((ticketExpiryDate.getTime() - currentDate.getTime()) / ONE_DAY_MS) + 1

      daysToUse = Math.min(stayToUse, ticket.unusedCount, daysUntilExpiry)

      if (daysToUse > 0) {
        usedTicketCounts.push({ [ticket.id]: daysToUse })
        stayToUse -= daysToUse
      }

      currentDate = new Date(currentDate.getTime() + daysToUse * ONE_DAY_MS)

      if (stayToUse <= 0) break
    }

    const startDateTime = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T00:00:00.000Z`
    const endDateTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T00:00:00.000Z`

    const reservation: ReservationRequest = {
      customerTicketIds: usedTicketCounts,
      customerId: selectedCustomer.customer.id,
      customerPetId: selectedCustomer.id,
      reservedAt: startDateTime,
      endAt: endDateTime,
    }

    setFinalData(reservation)
  }

  const stayOptions = getStayOptions()
  const isSelectDisabled =
    stayOptions.length === 0 || stayOptions.every((option) => option.disabled)

  return (
    <>
      <Wrapper direction='column'>
        <Flex direction='column' justify='center' gap='8px' css={{ padding: '16px' }}>
          <Flex gap='8px'>
            <Text typography='12_Rg' color='gray500' css={{ width: '80px' }}>
              잔여횟수
            </Text>

            <Text typography='13_Rg' color='gray500'>
              등록가능 일자
            </Text>
          </Flex>

          {getSortedTickets(tickets?.hotel).map((ticket: Ticket) => {
            const isExpired =
              new Date(ticket.expiredAt).getTime() <
              new Date(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`).getTime()

            return (
              <Flex key={ticket.id} align='flex-start' gap='8px'>
                <Text
                  typography='12_Rg'
                  color={isExpired ? 'gray400' : 'gray600'}
                  css={{ width: '80px' }}
                >
                  {`${ticket.unusedCount}회 남음`}
                </Text>

                <Text typography='13_Rg' color={isExpired ? 'gray400' : 'gray600'}>
                  {`${formatDate(ticket.expiredAt)}까지${isExpired ? ' / 선택불가' : ''}`}
                </Text>
              </Flex>
            )
          })}
        </Flex>

        <Flex
          css={{
            backgroundColor: `${colors.gray100}`,
            padding: '16px',
          }}
        >
          <Text typography='13_Rg' color='gray500'>
            기한 내에서 등록가능 일자가 빠른 것 먼저 소진되며 이후
            <br />
            날짜는 예약할 수 없습니다. 기한 내에 모두 사용해주세요.
          </Text>
        </Flex>
      </Wrapper>

      <Spacing size={40} />

      <Flex align='center'>
        <Text color='gray500' typography='14_Md' style={{ margin: '0px 2px 8px 0px' }}>
          횟수(박)
        </Text>
        <Text color='red600'>*</Text>
      </Flex>

      <CustomSelect
        options={getStayOptions()}
        onClick={selectStay}
        value={selectedStay}
        width='100%'
        placeHolder='횟수(박) 선택'
        disabled={isSelectDisabled}
      />
    </>
  )
}

export default SelectHotelTicket

const Wrapper = styled(Flex)`
  border: 1px solid ${colors.gray200};
  border-radius: 8px;
  overflow: hidden;
`
