import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRecoilValue } from 'recoil'

import { deleteDayOff } from '@apis/calendar/calendar'
import DayOffImg from '@assets/images/day-off.png'
import Text from '@common/Text'
import Flex from '@common/Flex'
import Button from '@common/Button'
import { selectedDateState } from '@atoms/selectedDate'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

interface DayOffProps {
  dayOffs: { id: number; dayOffAt: string }[]
}

const DayOff = ({ dayOffs }: DayOffProps) => {
  const petKindergardenId = useRecoilValue(petKindergardenIdState)

  const queryClient = useQueryClient()
  const selectedDate = useRecoilValue(selectedDateState)

  const dayOffId = dayOffs?.find(
    (dayOff) =>
      dayOff.dayOffAt === `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`,
  )?.id

  const { mutate: deleteDayOffMutation } = useMutation({
    mutationFn: (dayOffId: number) => {
      return deleteDayOff(petKindergardenId as number, dayOffId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['calendar'],
      })
    },
    onError: () => {},
  })

  const handleCancelDayOff = (dayOffId: number) => {
    deleteDayOffMutation(dayOffId)
  }

  return (
    <Flex justify='center' align='center' direction='column' css={{ height: '100vh' }}>
      <img src={DayOffImg} alt='휴무일 이미지' />

      <Text typography='24_Sb' color='gray600'>
        휴무일이다멍!
      </Text>

      {dayOffId !== undefined && (
        <Button
          onClick={() => handleCancelDayOff(dayOffId)}
          css={{ marginTop: '40px' }}
          color='line'
        >
          휴무일 취소
        </Button>
      )}
    </Flex>
  )
}

export default DayOff
