const CalendarTimeIcon = ({ isActive = false, size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='11.999' r='9' fill={isActive ? '#FFCE56' : '#C8CEDA'} />
      <path
        d='M12 8.99902V12.999H16'
        stroke={isActive ? '#FFA31E' : '#F3F4F9'}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CalendarTimeIcon
