const DayIcon = () => {
  return (
    <>
      <svg
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='19.9987' cy='20.0007' r='11.6667' fill='#F49396' />
        <path
          d='M19.998 3.33398L19.998 5.00065'
          stroke='#F49396'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M19.998 35L19.998 36.6667'
          stroke='#F49396'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path d='M36.666 20L34.9993 20' stroke='#F49396' strokeWidth='2' strokeLinecap='round' />
        <path d='M4.99805 20L3.33138 20' stroke='#F49396' strokeWidth='2' strokeLinecap='round' />
        <path
          d='M8.21484 8.21484L9.39336 9.39336'
          stroke='#F49396'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M30.6035 30.6064L31.782 31.785'
          stroke='#F49396'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M31.7832 8.21484L30.6047 9.39335'
          stroke='#F49396'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M9.39258 30.6064L8.21407 31.785'
          stroke='#F49396'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </svg>
    </>
  )
}

export default DayIcon
