import styled from '@emotion/styled'

import Flex from '@common/Flex'
import Text from '@common/Text'
import mung_loading from '@assets/images/mung_loading.png'

interface FullPageLoaderProps {
  message?: string
  width?: number
  height?: number
}

const FullPageLoader = ({ message, width = 130, height = 130 }: FullPageLoaderProps) => {
  return (
    <FullPageLoaderContainer align='center' justify='center'>
      <Flex direction='column' align='center' gap={46}>
        <img src={mung_loading} alt='로딩 이미지' width={width} height={height} />

        {message && (
          <Text typography='24_Sb' color='gray600'>
            {message}
          </Text>
        )}
      </Flex>
    </FullPageLoaderContainer>
  )
}

const FullPageLoaderContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default FullPageLoader
