import { useRecoilValue } from 'recoil'

import { Customer, Ticket, ReservationRequest } from '@/types/calendar'
import { formatDate } from '@utils/generateDate'
import CustomSelect from '@common/CustomSelect'
import { selectedDateState } from '@atoms/selectedDate'

interface SelectAllDayTicketProps {
  tickets: { allDay: Ticket[] }
  selectedTicket: Ticket | null
  selectedCustomer: Customer | null
  setSelectedTicket: (ticket: Ticket) => void
  setFinalData: (reservation: ReservationRequest) => void
}

const SelectAllDayTicket = ({
  tickets,
  selectedTicket,
  selectedCustomer,
  setSelectedTicket,
  setFinalData,
}: SelectAllDayTicketProps) => {
  const selectedDate = useRecoilValue(selectedDateState)

  // 종일 이용권 옵션 생성
  const getTicketOptions = () =>
    tickets?.allDay
      .sort(
        (a: Ticket, b: Ticket) => new Date(a.expiredAt).getTime() - new Date(b.expiredAt).getTime(),
      )
      .map((ticket: Ticket) => {
        const isExpired =
          new Date(ticket.expiredAt).getTime() <
          new Date(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`).getTime()
        return {
          value: ticket.id.toString(),
          label: `${ticket.unusedCount}회 남음 - ${formatDate(ticket.expiredAt)}까지 등록가능${isExpired ? ' / 선택불가' : ''}`,
          disabled: isExpired,
        }
      })

  // 종일 이용권 선택 후 최종 등록 데이터 생성
  const selectTicket = (selectedValue: string) => {
    const ticketId = parseInt(selectedValue, 10)
    const ticket = tickets.allDay.find((ticket: Ticket) => ticket.id === ticketId)

    if (ticket) {
      setSelectedTicket(ticket)

      if (selectedCustomer) {
        const reservation: ReservationRequest = {
          customerTicketIds: [{ [ticket.id]: 1 }],
          customerId: selectedCustomer.customer.id,
          customerPetId: selectedCustomer.id,
          reservedAt: `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T00:00:00.000Z`,
          endAt: `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T23:59:59.999Z`,
        }
        setFinalData(reservation)
      }
    }
  }

  return (
    <CustomSelect
      options={getTicketOptions()}
      onClick={selectTicket}
      value={selectedTicket ? selectedTicket.id.toString() : ''}
      width='100%'
      placeHolder='이용권 선택'
      disabled={!selectedCustomer}
    />
  )
}

export default SelectAllDayTicket
