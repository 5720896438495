import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import Flex from '@common/Flex'
import LeftArrow from '@icon/LeftArrow'
import RightArrow from '@icon/RightArrow'
import Text from '@common/Text'

interface PaginationProps {
  limit: number
  count: number
  next: string | null
  previous: string | null
  setOffset: (offset: number | any) => void
  currentPage: number
  setCurrentPage: (page: number) => void
}

const Pagination = ({
  count,
  limit,
  next,
  previous,
  currentPage,
  setCurrentPage,
  setOffset,
}: PaginationProps) => {
  const totalPage = Math.ceil(count / limit)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    setOffset((page - 1) * limit)
  }

  const groupSize = 5
  const start = Math.floor((currentPage - 1) / groupSize) * groupSize + 1
  const end = Math.min(start + groupSize - 1, totalPage)

  const renderPageNumbers = () => {
    const pageNumbers = []

    for (let page = start; page <= end; page++) {
      pageNumbers.push(
        <li key={page}>
          <PageButton
            onClick={() => handlePageChange(page)}
            currentPage={currentPage}
            active={currentPage === page}
          >
            <Text typography='14_Rg' color={currentPage === page ? 'gray600' : 'gray300'}>
              {page}
            </Text>
          </PageButton>
        </li>,
      )
    }

    return pageNumbers
  }

  return (
    <PaginationContainer gap={10}>
      <LeftButton disabled={previous === null} onClick={() => handlePageChange(currentPage - 1)}>
        <LeftArrow isDisable={Boolean(previous)} />
      </LeftButton>
      <Flex as='ul' gap={16}>
        {renderPageNumbers()}
      </Flex>
      <RightButton disabled={next === null} onClick={() => handlePageChange(currentPage + 1)}>
        <RightArrow isDisable={Boolean(next)} />
      </RightButton>
    </PaginationContainer>
  )
}

const DefaultButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 4px;
`

const PaginationContainer = styled(Flex)`
  margin-top: 20px;
`

const PageButton = styled(DefaultButton)<{
  currentPage: number
  active: boolean
}>`
  &:hover {
    ${({ currentPage }) =>
      currentPage &&
      css`
        background-color: ${colors.gray100};
        color: ${colors.gray600};
      `}
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${colors.gray100};
    `}
`

const LeftButton = styled(DefaultButton)`
  border: 1px solid ${colors.gray100};
`

const RightButton = styled(DefaultButton)``

export default Pagination
