import styled from '@emotion/styled'
import { InputHTMLAttributes } from 'react'

import Flex from '@common/Flex'
import Text from '@common/Text'
import checkfill from '@assets/checkfill.svg'

interface CheckboxFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  isChecked?: boolean
}

const CheckboxField = ({ label, isChecked, onChange, disabled, ...rest }: CheckboxFieldProps) => {
  return (
    <Flex align='center'>
      <Checkbox
        type='checkbox'
        label={!!label}
        checked={isChecked}
        disabled={disabled}
        onChange={onChange}
        {...rest}
      />
      <Text typography='14_Md' color='gray600' textAlign='center' disabled={disabled}>
        {label}
      </Text>
    </Flex>
  )
}

export default CheckboxField

const Checkbox = styled.input<{ label: boolean }>`
  appearance: none;
  border: 2px solid gainsboro;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  margin-right: ${({ label }) => (label ? '11px' : '0')};

  &:checked {
    background-image: url(${checkfill});
    background-size: fill;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
  }
`
