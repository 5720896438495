import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { useQuery } from '@tanstack/react-query'

import { getToken, getUser } from '@apis/user/user'
import FullPageLoader from '@common/FullPageLoader'
import { useInternalRouter } from '@hooks/useInternalRouter'
import { useUserCheck } from '@hooks/useUserCheck'
import { userState } from '@atoms/user'

const RedirectPage = () => {
  const router = useInternalRouter()

  const code = new URL(window.location.href).searchParams.get('code')

  const userInfo = useSetRecoilState(userState)
  const isUserCheck = useUserCheck()

  const { data, isLoading: isTokenLoading } = useQuery({
    queryKey: ['auth/kakao/callback'],
    queryFn: () => getToken(code as string),
  })

  const { data: userData, isLoading: isUserDataLoading } = useQuery({
    queryKey: ['users/profile'],
    queryFn: () => getUser(data?.accessToken),
    enabled: !!data?.accessToken,
  })

  useEffect(() => {
    if (userData) {
      userInfo({
        name: userData?.name,
        email: userData?.email,
        phoneNumber: userData?.phoneNumber,
      })
    }
  }, [userData, router, userInfo, isUserDataLoading])

  useEffect(() => {
    if (!isTokenLoading && !isUserDataLoading) {
      if (isUserCheck) {
        router.replace('/main/schedule')
      } else {
        router.replace('/signup')
      }
    }
  }, [isUserCheck, isTokenLoading, router, isUserDataLoading])

  return <>{isUserDataLoading && <FullPageLoader message='로그인 중 입니다.' />}</>
}

export default RedirectPage
