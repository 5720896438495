import styled from '@emotion/styled'

import Button from '@common/Button'
import Container from '@common/Container'
import Dimmed from '@common/Dimmed'
import Flex from '@common/Flex'
import Spacing from '@common/Spacing'
import Text from '@common/Text'

interface ModalProps {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  title?: string | React.ReactNode
  description?: React.ReactNode
  body?: React.ReactNode
  rightButtonLabel?: string
  leftButtonLabel?: string
  rightButtonOnClick?: (() => void) | ((param: any) => void)
  leftButtonOnClick?: () => void
  disabled?: boolean
  resetState?: (value: any) => void
  children?: React.ReactNode
  modalGap?: number
}

const Modal = ({
  showModal,
  setShowModal,
  title,
  body,
  description,
  leftButtonLabel = '취소',
  rightButtonLabel,
  rightButtonOnClick,
  disabled,
  resetState,
  children,
  modalGap,
}: ModalProps) => {
  if (!showModal) return null

  const close = () => {
    setShowModal(false)

    if (!resetState) return null
    resetState(null || 0)
  }

  return (
    <Dimmed>
      <Container>
        <ModalWrapper>
          <Flex direction='column' gap={modalGap}>
            {title && (
              <Text typography='24_Sb' color='gray600' textAlign='center'>
                {title}
              </Text>
            )}

            {description && <Spacing size={12} />}

            {description && (
              <Text typography='14_Rg' color='gray500' textAlign='center'>
                {description}
              </Text>
            )}

            {description && <Spacing size={20} />}

            {body}
          </Flex>

          {children && !description && <Spacing size={40} />}

          {children}

          <Spacing size={40} />

          <Flex gap={10}>
            <Button onClick={close} color='normal' size='mediumSmall'>
              {leftButtonLabel}
            </Button>
            <Button onClick={rightButtonOnClick} size='mediumSmall' disabled={disabled}>
              {rightButtonLabel}
            </Button>
          </Flex>
        </ModalWrapper>
      </Container>
    </Dimmed>
  )
}

const ModalWrapper = styled.div`
  padding: 56px 40px 40px 40px;
`

export default Modal
