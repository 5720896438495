import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'

export enum Method {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

const customAxios = async (method: Method, url: string, ...rest: { [key: string]: any }[]) => {
  const res = await axios[method](url, ...rest)

  return res.data
}

export default customAxios
