import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useSetRecoilState } from 'recoil'
import { useMutation } from '@tanstack/react-query'

import Complete from '@components/signup/Complete'
import FirstForm from '@components/signup/FirstForm'
import SecondForm from '@components/signup/SecondForm'
import { createKindergartenInfo, updateUserInfo } from '@apis/user/user'
import { userCheckState } from '@atoms/user'
import { addressValues, numberFormValues, SignupFormValues, userFormValues } from '@/types/form'

const SignupPage = () => {
  const setCheckUser = useSetRecoilState(userCheckState)
  const [step, setStep] = useState(() => {
    const startStep = localStorage.getItem('step')
    return startStep !== null ? Number(startStep) : 0
  })

  const { mutate: updateUser } = useMutation({
    mutationFn: (formValue: SignupFormValues) => {
      const { name, email } = formValue
      return updateUserInfo(name, email)
    },

    onSuccess: () => {
      setStep((prevStep) => prevStep + 1)
    },
  })
  const { mutate: updateKindergardenInfo } = useMutation({
    mutationFn: ({
      formValue,
      address,
      dogName,
      numberValue,
    }: {
      formValue: userFormValues
      address: addressValues
      dogName: string
      numberValue: numberFormValues
    }) => {
      const {
        mainThumbnailUrl,
        profileThumbnailUrl,
        businessStartHour,
        businessEndHour,
        guideMessage,
        reservationAvailabilityOption,
        reservationChangeOption,
        dailyPetLimit,
        detailAddress,
      } = formValue
      const { roadAddress, abbrAddress, shortAddress } = address
      const { userPhoneNumber, petKinderGardenPhoneNumber } = numberValue.visiblePhoneNumber

      const formValues = {
        name: dogName,
        mainThumbnailUrl,
        profileThumbnailUrl,
        phoneNumber: numberValue.phoneNumber,
        visiblePhoneNumber: {
          userPhoneNumber,
          petKinderGardenPhoneNumber,
        },
        businessStartHour,
        businessEndHour,
        guideMessage,
        reservationAvailabilityOption,
        reservationChangeOption,
        dailyPetLimit,
        roadAddress,
        abbrAddress,
        shortAddress,
        detailAddress,
      }

      return createKindergartenInfo(formValues)
    },

    onSuccess: () => {
      setStep((prevStep) => prevStep + 1)
      setCheckUser(true)
    },
  })

  useEffect(() => {
    localStorage.setItem('step', String(step))
  }, [step])

  return (
    <SignupContainer>
      {step === 0 && <FirstForm onNext={updateUser} />}
      {step === 1 && <SecondForm onNext={updateKindergardenInfo} setStep={setStep} />}
      {step === 2 && <Complete />}
    </SignupContainer>
  )
}

export default SignupPage

const SignupContainer = styled.div`
  width: 100%;
  max-width: 306px;
  margin: 64px auto;
`
