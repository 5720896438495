import styled from '@emotion/styled'
import { useState, useEffect } from 'react'
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  addDays,
} from 'date-fns'

import CalendarHeader from '@components/schedule/calendar/CalendarHeader'
import CalendarGrid from '@components/schedule/calendar/CalendarGrid'
import Flex from '@common/Flex'

interface CalendarProps {
  dailyReservations: any
  dayOffs: { id: number; dayOffAt: string }[]
  koreaSpecialDays?: {
    id: number
    name: string
    specialDayAt: string
    isHoliday: boolean
  }[]
  setCurrentMonth: (date: Date) => void
}

const Calendar = ({
  dailyReservations,
  dayOffs,
  koreaSpecialDays,
  setCurrentMonth,
}: CalendarProps) => {
  const [date, setDate] = useState(new Date())
  const [days, setDays] = useState<Array<{ date: Date; isCurrentMonth: boolean }>>([])

  useEffect(() => {
    updateCalendar(date)
  }, [date])

  const updateCalendar = (date: Date) => {
    const start = startOfWeek(startOfMonth(date))
    const end = endOfWeek(endOfMonth(date))
    let calendarDays = eachDayOfInterval({ start, end }).map((day) => ({
      date: day,
      isCurrentMonth: isSameMonth(day, date),
    }))

    if (calendarDays.length < 42) {
      const additionalDays = eachDayOfInterval({
        start: addDays(end, 1),
        end: addDays(end, 42 - calendarDays.length),
      })

      calendarDays = [
        ...calendarDays,
        ...additionalDays.map((day) => ({
          date: day,
          isCurrentMonth: isSameMonth(day, date),
        })),
      ]
    }

    setDays(calendarDays)
  }

  return (
    <Container direction='column'>
      <CalendarHeader date={date} setDate={setDate} setCurrentMonth={setCurrentMonth} />

      <CalendarGrid
        days={days}
        koreaSpecialDays={koreaSpecialDays}
        dailyReservations={dailyReservations}
        dayOffs={dayOffs}
      />
    </Container>
  )
}

export default Calendar

const Container = styled(Flex)`
  width: 100%;
  height: 100vh;
  padding: 0 60px 60px 60px;
  box-sizing: border-box;
`
