import { useQuery } from '@tanstack/react-query'

import { getTickets } from '@apis/ticket/ticket'
import { TicketsData } from '@/types/ticket'

export const useTickets = (petKindergardenId: number) => {
  const { data: tickets } = useQuery<TicketsData>({
    queryKey: ['pet-kindergardens'],
    queryFn: () => getTickets(petKindergardenId),
    enabled: !!petKindergardenId,
    select: (data) => ({
      ...data,
      data: data.data.sort((a, b) => (a.isDeleted === b.isDeleted ? 0 : a.isDeleted ? 1 : -1)),
    }),
  })

  return { tickets }
}
