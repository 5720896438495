import { useEffect, useState } from 'react'

import FullPageLoader from '@common/FullPageLoader'

const Loading = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleLoading = () => {
      setIsLoading(true)
    }

    window.addEventListener('beforeunload', handleLoading)

    return () => {
      window.removeEventListener('beforeunload', handleLoading)
    }
  }, [])

  if (isLoading) {
    return <FullPageLoader message='잠시만 기다려주세요' />
  }

  return <>{children}</>
}

export default Loading
