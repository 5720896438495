import Cookies from 'js-cookie'

import { Method } from '@utils/axios'
import { privateAxios } from '@utils/newAxios'

export const getTickets = async (petKindergardenId: number) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${petKindergardenId}/tickets`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
        },
      },
    )

    return response
  } catch (e) {
    console.error(e)
  }
}

export const createTicket = async (petKindergardenId: number, formValues: any) => {
  try {
    const response = await privateAxios(
      Method.POST,
      `pet-kindergardens/${petKindergardenId}/tickets`,
      formValues,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
        },
      },
    )

    return response
  } catch (e) {
    throw e
  }
}

export const deleteTicket = async (petKindergardenId: number, ticketId: number) => {
  try {
    const response = await privateAxios(
      Method.DELETE,
      `pet-kindergardens/${petKindergardenId}/tickets/${ticketId}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
        },
      },
    )

    return response
  } catch (e) {
    console.error(e)
  }
}
