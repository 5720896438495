import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { buttonColor, ButtonColor, buttonSize, ButtonSize } from '@styles/button'

interface ButtonProps {
  color?: ButtonColor
  size?: ButtonSize
  full?: boolean
  disabled?: boolean
}

const Button = styled.button<ButtonProps>(
  {
    cursor: 'pointer',
    borderRadius: '10px',
  },

  ({ size = 'small' }) => buttonSize[size],
  ({ color = 'primary' }) => buttonColor[color],
  ({ full }) =>
    full
      ? css`
          display: block;
          width: 100%;
        `
      : undefined,

  ({ disabled }) =>
    disabled
      ? css`
          opacity: 0.25;
          cursor: not-allowed;
        `
      : undefined,
)

export default Button
