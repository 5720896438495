import React from 'react'
import styled from '@emotion/styled'

import Flex from '@common/Flex'
import Text from '@common/Text'

interface NoListProps {
  title: string
  imgIcon?: React.ReactNode
  top: string
  left: string
}

const NoList = ({ title, imgIcon, top, left }: NoListProps) => {
  return (
    <NoListContainer top={top} left={left} align='center'>
      {imgIcon}
      <Text typography='18_Md' color='gray500'>
        {title}
      </Text>
    </NoListContainer>
  )
}

const NoListContainer = styled(Flex)<{ top: string; left: string }>`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`

export default NoList
