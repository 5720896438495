import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useRecoilValue } from 'recoil'

import { postDayOff } from '@apis/calendar/calendar'
import NotListImg from '@assets/images/not_list.png'
import Text from '@common/Text'
import Flex from '@common/Flex'
import Button from '@common/Button'
import { selectedDateState } from '@atoms/selectedDate'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

const NoReservation = () => {
  const petKindergardenId = useRecoilValue(petKindergardenIdState)
  const queryClient = useQueryClient()
  const selectedDate = useRecoilValue(selectedDateState)

  const { mutate: postDayOffMutation } = useMutation({
    mutationFn: (date: string) => {
      return postDayOff(petKindergardenId as number, date)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['calendar'],
      })
    },
    onError: () => {},
  })

  const handleDayOff = () => {
    const date = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`
    postDayOffMutation(date)
  }

  return (
    <Flex justify='center' align='center' css={{ height: '100vh' }} direction='column'>
      <img src={NotListImg} alt='예약 없음 이미지' css={{ width: '130px', height: '130px' }} />

      <Text typography='24_Sb' color='gray600'>
        예약이 없다멍
      </Text>

      <Button onClick={handleDayOff} color='alert' css={{ marginTop: '40px' }}>
        휴무일 지정
      </Button>
    </Flex>
  )
}

export default NoReservation
