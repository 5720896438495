const TimeIcon = () => {
  return (
    <>
      <svg
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='20' cy='20' r='15' fill='#FFCE56' />
        <path
          d='M20 15V21.6667H26.6667'
          stroke='#FFA31E'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </>
  )
}

export default TimeIcon
