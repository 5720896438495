import { useQuery } from '@tanstack/react-query'
import { useRecoilValue } from 'recoil'

import { getDailyReservations } from '@apis/calendar/calendar'
import { SelectedDate } from '@/types/calendar'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

export const useDailyReservations = (selectedDate: SelectedDate) => {
  const petKindergardenId = useRecoilValue(petKindergardenIdState)

  return useQuery({
    queryKey: ['calendar', selectedDate.year, selectedDate.month, selectedDate.day],
    queryFn: () =>
      getDailyReservations(
        `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`,
        petKindergardenId as number,
      ),
    enabled: !!petKindergardenId,
  })
}
