import { ComponentProps, createContext, useCallback, useContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import Alert from '@common/Alert'

type AlertProps = ComponentProps<typeof Alert>
type AlertOptions = Omit<AlertProps, 'open'>

interface AlertContextValue {
  open: (options: AlertOptions) => void
}

const Context = createContext<AlertContextValue | undefined>(undefined)

const defaultValues: AlertProps = {
  open: false,
  title: null,
  description: null,
  buttonLabel: '',
  onComplete: () => {},
}

export const AlertContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [alert, setAlert] = useState(defaultValues)

  const portalRoot = document.getElementById('portal')

  const close = useCallback(() => {
    setAlert(defaultValues)
  }, [])

  const open = useCallback(
    ({ onComplete, ...options }: AlertOptions) => {
      setAlert({
        ...options,
        onComplete: () => {
          close()
          onComplete()
        },
        open: true,
      })
    },
    [close],
  )

  const values = useMemo(() => ({ open }), [open])

  return (
    <Context.Provider value={values}>
      {children}
      {portalRoot !== null ? createPortal(<Alert {...alert} />, portalRoot) : null}
    </Context.Provider>
  )
}

export const useAlertContext = () => {
  const values = useContext(Context)

  if (values === undefined) {
    throw new Error('AlertContext 내부에서 사용해주세요.')
  }

  const { open } = values

  return { open }
}
