import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { colors } from '@styles/colors'
import Icon_Arrow from '@assets/Icon_Arrow.svg'
import Flex from '@common/Flex'
import Text from '@common/Text'

interface OptionType {
  label: string
  value: string
}

interface SelectBoxProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options?: OptionType[]
  type?: string
  label?: string
  required?: boolean
  width?: string
  disabled?: boolean
  placeHolder?: string
}

const SelectBox = ({
  options = [],
  type,
  label,
  required,
  width = '200px',
  disabled = false,
  placeHolder,
  ...rest
}: SelectBoxProps) => {
  const createTimeOptions = () => {
    const options = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        options.push({ value, label: value })
      }
    }
    return options
  }

  const finalOptions = type === 'time' ? createTimeOptions() : options

  return (
    <>
      {label && (
        <Flex align='center'>
          <Text color='gray500' typography='14_Md' css={TextStyles}>
            {label}
          </Text>
          {required && <Text color='red600'>*</Text>}
        </Flex>
      )}

      <Select {...rest} width={width} disabled={disabled}>
        {placeHolder && (
          <option disabled hidden value=''>
            {placeHolder}
          </option>
        )}

        {finalOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </>
  )
}

const TextStyles = css`
  margin-bottom: 8px;
  margin-right: 2px;
`

export default SelectBox

const Select = styled.select<{ width: string; disabled: boolean }>`
  width: ${({ width }) => width};
  padding: 0 16px;
  height: 48px;
  border: 1px solid ${colors.gray200};
  border-radius: 6px;
  box-sizing: border-box;

  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: url(${Icon_Arrow}) no-repeat calc(100% - 16px) 50%;

  background-color: ${({ disabled }) => (disabled ? colors.gray100 : 'white')};

  &:focus {
    outline: none;
    border-color: ${colors.gray400};
  }
`
