import { useMutation } from '@tanstack/react-query'

import { createTicket } from '@apis/ticket/ticket'
import { TicketFormValues } from '@/types/ticket'

interface useCreateTicketProps {
  onSuccess: () => void
  onError: () => void
  petKindergardenId: number
}

export const useCreateTicket = ({ onSuccess, onError, petKindergardenId }: useCreateTicketProps) =>
  useMutation({
    mutationFn: (formValues: TicketFormValues) => createTicket(petKindergardenId, formValues),
    onSuccess: () => {
      onSuccess()
    },
    onError: () => {
      onError()
    },
  })
