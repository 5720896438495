import { useRecoilValue } from 'recoil'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import { validate } from '@utils/validation'
import Flex from '@common/Flex'
import Spacing from '@common/Spacing'
import TextField from '@common/TextField'
import Button from '@common/Button'
import Text from '@common/Text'
import FormHead from '@components/signup/FormHead'
import { userState } from '@atoms/user'
import { SignupFormValues } from '@/types/form'

const FirstForm = ({ onNext }: { onNext: (formValue: SignupFormValues) => void }) => {
  const userInfo = useRecoilValue(userState)
  const [formValue, setFormValue] = useState<SignupFormValues>({
    name: userInfo.name,
    email: userInfo.email,
    phone: userInfo.phoneNumber,
  })

  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target

      setFormValue({
        ...formValue,
        [name]: value,
      })
    },
    [formValue],
  )

  const handleFormValidation = (formValues: SignupFormValues) => {
    let error: Partial<SignupFormValues> = {}

    if (!validate.isEmail(formValues.email)) {
      error.email = '이메일 형식이 올바르지 않습니다.'
    }

    if (!validate.isName(formValues.name)) {
      error.name = '한글, 영문 대소문자만 입력 가능합니다.'
    }

    return error
  }

  const errorCheck = useMemo(() => handleFormValidation(formValue), [formValue])

  const 필수값이입력되었는가 = Object.values(formValue).every((v) => v)

  return (
    <Flex direction='column'>
      <FormHead />

      <TextField
        label='사장님 성함'
        name='name'
        placeholder='홍길동'
        required
        value={formValue.name}
        onChange={handleChangeValue}
        hasError={Boolean(formValue.name) && Boolean(errorCheck.name)}
        errorMessage={Boolean(formValue.name) && errorCheck.name}
      />

      <Spacing size={24} />

      <TextField
        label='이메일 주소'
        name='email'
        placeholder='ID@email.com'
        required
        value={formValue.email}
        onChange={handleChangeValue}
        hasError={Boolean(formValue.email) && Boolean(errorCheck.email)}
        errorMessage={Boolean(formValue.email) && errorCheck.email}
      />

      <Spacing size={24} />

      <TextField
        label='휴대폰 번호'
        name='phone'
        placeholder='010-1234-5678'
        required
        value={formValue.phone}
        onChange={handleChangeValue}
        hasError={Boolean(formValue.phone) && Boolean(errorCheck.phone)}
        disabled
      />

      <Spacing size={64} />

      <Text typography='12_Rg' color='gray500'>
        해당 연락처로 멍매니저 공지사항 및 보호자 예약을 안내해드려요. <br />
        입력 정보를 한번 더 확인해주세요.
      </Text>

      <Spacing size={14} />

      <Button
        disabled={!필수값이입력되었는가}
        size='large'
        onClick={() => {
          onNext(formValue)
        }}
      >
        <Text typography='14_Md' color='white'>
          다음
        </Text>
      </Button>
    </Flex>
  )
}

export default FirstForm
