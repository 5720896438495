import { Method } from '@utils/axios'
import { privateAxios } from '@utils/newAxios'

export const searchKindergarden = async (name: string) => {
  try {
    const response = await privateAxios(Method.GET, `pet-kindergardens/search?name=${name}`)

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}
