import styled from '@emotion/styled'
import { useState } from 'react'

import { Reservation } from '@/types/calendar'
import Text from '@common/Text'
import Flex from '@common/Flex'
import CalendarTimeIcon from '@components/icon/CalendarTimeIcon'
import CalendarDayIcon from '@components/icon/CalendarDayIcon'
import CalendarHotelIcon from '@components/icon/CalendarHotelIcon'
import ReservationItem from '@components/schedule/ListByDate/ReservationItem'

interface ScheduleListProps {
  dailyReservations: {
    time: any[]
    allDay: any[]
    hotel: any[]
  }
  handleShowToast: (message: string) => void
}

const ScheduleList = ({ dailyReservations, handleShowToast }: ScheduleListProps) => {
  const [openMenuId, setOpenMenuId] = useState<number | null>(null)

  const handleToggleMenu = (id: number | null) => {
    setOpenMenuId((prevId) => (prevId === id ? null : id))
  }

  return (
    <Container>
      {dailyReservations?.time?.length > 0 && (
        <ListItem>
          <Flex align='center' gap='4px'>
            <CalendarTimeIcon isActive={true} />
            <Text typography='14_Md' color='yellow600'>
              시간권
            </Text>
          </Flex>

          {dailyReservations.time.map((reservation: Reservation) => (
            <ReservationItem
              key={reservation.id}
              reservation={reservation}
              type='time'
              openMenuId={openMenuId}
              handleToggleMenu={handleToggleMenu}
              handleShowToast={handleShowToast}
            />
          ))}
        </ListItem>
      )}

      {dailyReservations?.allDay?.length > 0 && (
        <ListItem>
          <Flex align='center' gap='4px'>
            <CalendarDayIcon isActive={true} />
            <Text typography='14_Md' color='red600'>
              종일권
            </Text>
          </Flex>
          {dailyReservations.allDay.map((reservation: Reservation) => (
            <ReservationItem
              key={reservation.id}
              reservation={reservation}
              type='allDay'
              openMenuId={openMenuId}
              handleToggleMenu={handleToggleMenu}
              handleShowToast={handleShowToast}
            />
          ))}
        </ListItem>
      )}

      {dailyReservations?.hotel?.length > 0 && (
        <ListItem>
          <Flex align='center' gap='4px'>
            <CalendarHotelIcon isActive={true} />
            <Text typography='14_Md' color='blue600'>
              호텔권
            </Text>
          </Flex>
          {dailyReservations.hotel.map((reservation: Reservation) => (
            <ReservationItem
              key={reservation.id}
              reservation={reservation}
              type='hotel'
              openMenuId={openMenuId}
              handleToggleMenu={handleToggleMenu}
              handleShowToast={handleShowToast}
            />
          ))}
        </ListItem>
      )}
    </Container>
  )
}

export default ScheduleList

const Container = styled.div`
  padding: 20px 0;
`

const ListItem = styled.div`
  padding-bottom: 40px;
`
