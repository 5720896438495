import styled from '@emotion/styled'
import { colors } from '@styles/colors'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'

import CancleIcon from '@assets/Icon_cancle.svg'
import { Customer } from '@/types/calendar'
import useDebounce from '@hooks/useDebounce'
import { searchCustomers } from '@apis/calendar/calendar'
import TextField from '@common/TextField'
import Spacing from '@common/Spacing'
import Text from '@common/Text'
import Flex from '@common/Flex'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

interface CustomerSearchProps {
  selectedCustomer: Customer | null
  setSelectedCustomer: (customer: Customer | null) => void
  setSelectedType: (type: string) => void
  setSelectedTicket: (timeTicket: any) => void
  setSelectedUsageTime: (usageTime: string) => void
}

const CustomerSearch = ({
  selectedCustomer,
  setSelectedCustomer,
  setSelectedType,
  setSelectedTicket,
  setSelectedUsageTime,
}: CustomerSearchProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const petKindergardenId = useRecoilValue(petKindergardenIdState)

  const { data: suggestions = [] } = useQuery({
    queryKey: ['searchCustomers', debouncedSearchTerm],
    queryFn: () => searchCustomers(petKindergardenId as number, debouncedSearchTerm),
    enabled: !!debouncedSearchTerm,
  })

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleSuggestionClick = (customer: Customer) => {
    setSelectedCustomer(customer)
    setSearchTerm('')
  }

  return (
    <>
      {!selectedCustomer && (
        <>
          <TextField
            placeholder='보호자성명, 휴대폰번호, 강아지 이름으로 검색'
            value={searchTerm}
            onChange={handleSearchChange}
          />

          {suggestions?.results?.length > 0 && (
            <SuggestionWrap>
              <Spacing size={4} />

              <SuggestionsList>
                {suggestions?.results?.map((suggestion: Customer) => (
                  <SuggestionItem
                    direction='column'
                    gap='4px'
                    key={suggestion.id}
                    onClick={() => handleSuggestionClick(suggestion)}
                    css={{ cursor: 'pointer' }}
                    justify='center'
                  >
                    <Text typography='14_Md' color='gray600'>
                      {suggestion.name}
                    </Text>
                    <Flex gap={4}>
                      <Text typography='12_Rg' color='gray500'>
                        {suggestion.customer.name}
                      </Text>
                      <Text typography='12_Rg' color='gray500'>
                        {suggestion.customer.phoneNumber}
                      </Text>
                    </Flex>
                  </SuggestionItem>
                ))}
              </SuggestionsList>
            </SuggestionWrap>
          )}
        </>
      )}

      {selectedCustomer && (
        <SuggestionsList>
          <SuggestionItem align='center' justify='space-between'>
            <Flex direction='column' gap='4px'>
              <Text typography='14_Md' color='gray600'>
                {selectedCustomer.name}
              </Text>
              <Flex gap={4}>
                <Text typography='12_Rg' color='gray500'>
                  {selectedCustomer.customer.name}
                </Text>
                <Text typography='12_Rg' color='gray500'>
                  {selectedCustomer.customer.phoneNumber}
                </Text>
              </Flex>
            </Flex>

            <Flex
              onClick={() => {
                setSelectedCustomer(null)
                setSelectedType('')
                setSelectedTicket(null)
                setSelectedUsageTime('')
              }}
              css={{ cursor: 'pointer' }}
            >
              <img src={CancleIcon} alt='cancle' />
            </Flex>
          </SuggestionItem>
        </SuggestionsList>
      )}
    </>
  )
}

export default CustomerSearch

const SuggestionWrap = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
`

const SuggestionsList = styled.div`
  border: 1px solid ${colors.gray200};
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  background-color: ${colors.white};

  &::-webkit-scrollbar {
    display: none;
  }
`

const SuggestionItem = styled(Flex)`
  padding: 0 16px;
  height: 56px;
  box-sizing: border-box;

  &:hover {
    background-color: ${colors.gray100};
  }
`
