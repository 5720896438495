import { TicketFormValues } from '@/types/ticket'
import TextField from '@common/TextField'
import Spacing from '@common/Spacing'

interface TicketFormProps {
  selectedOption: string
  formValues: TicketFormValues
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const TicketForm = ({ selectedOption, formValues, handleChange }: TicketFormProps) => {
  return (
    <>
      {['time', 'allDay', 'hotel'].includes(selectedOption) && (
        <>
          {selectedOption === 'time' && (
            <>
              <TextField
                label='시간'
                required
                name='usageTime'
                onChange={handleChange}
                value={formValues.usageTime}
                unit='시간 / 1회'
              />
              <Spacing size={24} />
            </>
          )}

          <TextField
            label='횟수'
            required
            name='usageCount'
            onChange={handleChange}
            value={formValues.usageCount}
            unit='회'
          />

          <Spacing size={24} />

          <TextField
            label='사용기간'
            required
            name='usagePeriodInDaysCount'
            onChange={handleChange}
            value={formValues.usagePeriodInDaysCount}
            textDescription='등록일로부터 이용권을 적용할 수 있는 기간'
            unit='일'
          />

          <Spacing size={24} />

          <TextField
            label='금액'
            required
            name='price'
            onChange={handleChange}
            value={formValues.price}
            unit='원'
          />
        </>
      )}
    </>
  )
}

export default TicketForm
