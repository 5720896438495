import Cookies from 'js-cookie'

import { Method } from '@utils/axios'
import { privateAxios } from '@utils/newAxios'

export const getMonthlyReservations = async (
  month: number,
  year: number,
  pet_kindergarden_id: number,
) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${pet_kindergarden_id}/reservations/calendar?month=${month}&year=${year}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
        },
      },
    )

    return response.data
  } catch (e) {
    console.error(e)
  }
}

export const getDailyReservations = async (date: string, pet_kindergarden_id: number) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${pet_kindergarden_id}/reservations?reserved_at=${date}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
        },
      },
    )
    return response.data
  } catch (e) {
    console.error(e)
  }
}

export const toggleIsAttended = async (petKindergardenId: number, reservationId: number) => {
  try {
    const response = await privateAxios(
      Method.PATCH,
      `pet-kindergardens/${petKindergardenId}/reservations/${reservationId}/toggle-is-attended`,
    )

    return response.data
  } catch (e) {
    console.error(e)
  }
}

export const postDayOff = async (pet_kindergarden_id: number, date: string) => {
  try {
    const response = await privateAxios(
      Method.POST,
      `pet-kindergardens/${pet_kindergarden_id}/reservations/day-off`,
      {
        dayOffAt: date,
      },
    )

    return response.data
  } catch (e) {
    console.error(e)
  }
}

export const deleteDayOff = async (pet_kindergarden_id: number, day_off_id: number) => {
  try {
    const response = await privateAxios(
      Method.DELETE,
      `pet-kindergardens/${pet_kindergarden_id}/reservations/day-off/${day_off_id}`,
    )

    return response.data
  } catch (e) {
    console.error(e)
  }
}

export const searchCustomers = async (pet_kindergarden_id: number, keyword: string) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${pet_kindergarden_id}/reservations/customers/pets?keyword=${keyword}`,
    )

    return response.data
  } catch (e) {
    console.error(e)
  }
}

export const getCustomerTickets = async (pet_kindergarden_id: number, customer_id: number) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${pet_kindergarden_id}/reservations/customers/${customer_id}/tickets`,
    )

    return response.data
  } catch (e) {
    console.error(e)
  }
}

export const postReservation = async (pet_kindergarden_id: number, reservation: any) => {
  try {
    const response = await privateAxios(
      Method.POST,
      `pet-kindergardens/${pet_kindergarden_id}/reservations`,
      reservation,
    )

    return response.data
  } catch (e) {
    throw e
  }
}

export const deleteReservation = async (pet_kindergarden_id: number, reservation_id: number) => {
  try {
    const response = await privateAxios(
      Method.DELETE,
      `pet-kindergardens/${pet_kindergarden_id}/reservations/${reservation_id}`,
    )

    return response.data
  } catch (e) {
    console.error(e)
  }
}

export const getDayOff = async (
  petKindergardenId: number,
  endDate: string | null,
  startDate: string | null,
) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${petKindergardenId}/reservations/day-off-range?end_date=${endDate}&start_date=${startDate}`,
    )

    return response.data
  } catch (e) {
    console.error(e)
  }
}
