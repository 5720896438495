import { colors } from '@styles/colors'

const LeftArrow = ({ isDisable }: { isDisable: boolean }) => {
  const hasColor = isDisable ? `${colors.gray600}` : `${colors.gray300}`

  return (
    <>
      <svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M5 1L1 5L5 9' stroke={hasColor} strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </>
  )
}

export default LeftArrow
