import { Method } from '@utils/axios'
import { privateAxios } from '@utils/newAxios'

// 반려동물 유치원 고객 목록 조회
export const getKindergardenCustomers = async (
  pet_kindergarden_id: number,
  offset: number,
  keyword: { name: string; phoneNumber: string; petName: string },
  is_active?: boolean,
) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${pet_kindergarden_id}/customers`,
      {
        params: {
          is_active: is_active,
          offset,
          limit: 10,
          customer_name: keyword.name,
          customer_phone_number: keyword.phoneNumber,
          customer_pet_name: keyword.petName,
        },
      },
    )

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}

// 반려동물 유치원 고객 생성
export const createKindergardenCustomer = async ({
  pet_kindergarden_id,
  name,
  phoneNumber,
  pets,
}: {
  pet_kindergarden_id: number
  name: string
  phoneNumber: string
  pets: string[]
}) => {
  try {
    const response = await privateAxios(
      Method.POST,
      `pet-kindergardens/${pet_kindergarden_id}/customers`,
      {
        name,
        phoneNumber,
        pets,
      },
    )

    return response.data
  } catch (e) {
    console.log('error', e)
    throw e
  }
}

// 반려동물 유치원 고객 일괄 등록

// 반려동물 유치원 고객 상세 조회
export const getKindergardenCustomersDetail = async (
  pet_kindergarden_id: number,
  customer_id: number,
) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${pet_kindergarden_id}/customers/${customer_id}`,
    )

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}

// 반려동물 유치원 고객 수정
export const updateKindergardenCustomer = async ({
  pet_kindergarden_id,
  customer_id,
  name,
  phoneNumber,
  petsToAdd,
  petsToDelete,
  memo,
}: {
  pet_kindergarden_id: number
  customer_id: number
  name: string
  phoneNumber: string
  petsToAdd?: string[]
  petsToDelete?: string[]
  memo?: string
}) => {
  try {
    const response = await privateAxios(
      Method.PUT,
      `pet-kindergardens/${pet_kindergarden_id}/customers/${customer_id}`,

      {
        name,
        phoneNumber,
        petsToAdd,
        petsToDelete,
        memo,
      },
    )

    return response.data
  } catch (e) {
    console.log('error', e)
    throw e
  }
}

// 반려동물 유치원 고객 반려동물 삭제
export const deleteCustomerPet = async (
  pet_kindergarden_id: number,
  customer_id: number,
  pet_id: number,
) => {
  try {
    const response = await privateAxios(
      Method.DELETE,
      `pet-kindergardens/${pet_kindergarden_id}/customers/${customer_id}/pets/${pet_id}`,
    )

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}

// 반려동물 고객 정보 이용권 등록 목록 조회
export const getKindergardenCustomersTicket = async (
  pet_kindergarden_id: number,
  customer_id: number,
  offset: number,
) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${pet_kindergarden_id}/customers/${customer_id}/tickets`,
      {
        params: {
          limit: 5,
          offset,
        },
      },
    )

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}

// 반려동물 고객 정보 이용권 등록
export const createKindergardenCustomersTicket = async (
  pet_kindergarden_id: number,
  customer_id: number,
  ticket_id: number | null,
) => {
  try {
    const response = await privateAxios(
      Method.POST,
      `pet-kindergardens/${pet_kindergarden_id}/customers/${customer_id}/tickets/${ticket_id}`,
    )

    return response.data
  } catch (e) {
    console.log('error', e)
    throw e
  }
}

// 반려동물 고객 이용권 사용 내역 목록 조회
export const getKindergardenCustomersTicketLog = async (
  pet_kindergarden_id: number,
  customer_id: number,
  offset: number,
) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${pet_kindergarden_id}/customers/${customer_id}/tickets/logs`,
      {
        params: {
          limit: 5,
          offset,
        },
      },
    )

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}

// 고객 활성화/비활성화
export const updateCustomerActive = async ({
  pet_kindergarden_id,
  customer_id,
}: {
  pet_kindergarden_id: number
  customer_id: number
}) => {
  try {
    const response = await privateAxios(
      Method.PATCH,
      `pet-kindergardens/${pet_kindergarden_id}/customers/${customer_id}/toggle-is-active`,
    )

    return response.data
  } catch (e) {
    console.log('error', e)
    throw e
  }
}

// 반려동물 유치원 고객 티켓 목록 조회
export const getKindergardenCustomersTicketList = async (pet_kindergarden_id: number) => {
  try {
    const response = await privateAxios(
      Method.GET,
      `pet-kindergardens/${pet_kindergarden_id}/customers/tickets`,
    )

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}
