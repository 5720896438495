import { useRecoilValue } from 'recoil'

import { Customer, Ticket, ReservationRequest } from '@/types/calendar'
import { formatDate } from '@utils/generateDate'
import Spacing from '@common/Spacing'
import Text from '@common/Text'
import Flex from '@common/Flex'
import CustomSelect from '@common/CustomSelect'
import { selectedDateState } from '@atoms/selectedDate'

interface SelectTimeTicketProps {
  tickets: { time: Ticket[] }
  selectedUsageTime: string
  selectedCustomer: Customer | null
  selectedTicket: Ticket | null
  startHour: string
  isTimeExceeded: boolean
  petKindergardenInfo: any
  setIsTimeExceeded: (isTimeExceeded: boolean) => void
  setFinalData: (reservation: ReservationRequest) => void
  setSelectedUsageTime: (usageTime: string) => void
  setSelectedTicket: (ticket: Ticket | null) => void
  setStartHour: (startHour: string) => void
}

const SelectTimeTicket = ({
  tickets,
  selectedUsageTime,
  selectedCustomer,
  selectedTicket,
  startHour,
  isTimeExceeded,
  petKindergardenInfo,
  setIsTimeExceeded,
  setFinalData,
  setSelectedUsageTime,
  setSelectedTicket,
  setStartHour,
}: SelectTimeTicketProps) => {
  const selectedDate = useRecoilValue(selectedDateState)

  // 선택 가능한 시간 단위 생성
  const getUsageTimeOptions = () => {
    const uniqueUsageTimes = [...new Set(tickets.time.map((ticket: Ticket) => ticket.usageTime))]

    return uniqueUsageTimes.map((usageTime: any) => ({
      value: usageTime.toString(),
      label: `${usageTime}시간 이용권`,
    }))
  }

  // 이용할 시간 단위 선택
  const selectUsageTime = (selectedValue: string) => {
    setSelectedUsageTime(selectedValue)
    setSelectedTicket(null)
  }

  // 선택한 시간 단위에 따른 이용권 생성
  const getTicketsByUsageTime = () =>
    tickets?.time
      .filter((ticket: Ticket) => ticket?.usageTime?.toString() === selectedUsageTime)
      .sort(
        (a: Ticket, b: Ticket) => new Date(a.expiredAt).getTime() - new Date(b.expiredAt).getTime(),
      )
      .map((ticket: Ticket) => {
        const isExpired =
          new Date(ticket.expiredAt).getTime() <
          new Date(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`).getTime()
        return {
          value: ticket.id.toString(),
          label: `${ticket.unusedCount}회 남음 - ${formatDate(ticket.expiredAt)}까지 등록가능${isExpired ? ' / 선택불가' : ''}`,
          disabled: isExpired,
        }
      })

  // 이용할 시간 이용권 선택
  const selectTicket = (selectedValue: any) => {
    const ticketId = parseInt(selectedValue, 10)
    const ticket = tickets.time.find((ticket: Ticket) => ticket.id === ticketId) || null
    setSelectedTicket(ticket)
  }

  // 등원 시간 옵션 생성 (영업시간 기준)
  const getStartHourOptions = () => {
    if (!petKindergardenInfo) return []

    const { businessStartHour, businessEndHour } = petKindergardenInfo
    const startHour = parseInt(businessStartHour.split(':')[0], 10)
    const endHour = parseInt(businessEndHour.split(':')[0], 10)
    const options = []

    for (let hour = startHour; hour <= endHour - 1; hour++) {
      options.push({
        value: `${hour.toString().padStart(2, '0')}:00`,
        label: `${hour.toString().padStart(2, '0')}:00`,
      })
      options.push({
        value: `${hour.toString().padStart(2, '0')}:30`,
        label: `${hour.toString().padStart(2, '0')}:30`,
      })
    }

    return options
  }

  // 등원 시간 선택
  const selectStarHour = (selectedValue: any) => {
    setStartHour(selectedValue)
    finalizeReservation(selectedValue)
  }

  // 최종 등록 데이터 생성
  const finalizeReservation = (startHour: any) => {
    if (!selectedCustomer || !selectedTicket) return

    const startDateTime = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T${startHour}:00.000Z`
    const ticketDuration = selectedTicket.usageTime

    if (ticketDuration === undefined) {
      return
    }

    const endDateTime = new Date(
      new Date(startDateTime).getTime() + ticketDuration * 60 * 60 * 1000,
    ).toISOString()

    const businessEndDateTime = new Date(
      `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T${petKindergardenInfo.businessEndHour}.000Z`,
    ).toISOString()

    if (endDateTime > businessEndDateTime) {
      setIsTimeExceeded(true)
    } else {
      setIsTimeExceeded(false)
    }

    const reservation: ReservationRequest = {
      customerTicketIds: [{ [selectedTicket.id]: 1 }],
      customerId: selectedCustomer.customer.id,
      customerPetId: selectedCustomer.id,
      reservedAt: startDateTime,
      endAt: endDateTime,
    }

    setFinalData(reservation)
  }

  return (
    <>
      <CustomSelect
        options={getUsageTimeOptions()}
        onClick={selectUsageTime}
        value={selectedUsageTime}
        width='100%'
        placeHolder='시간 선택'
        disabled={!selectedCustomer}
      />

      <Spacing size={8} />

      <CustomSelect
        options={getTicketsByUsageTime()}
        onClick={selectTicket}
        value={selectedTicket ? selectedTicket.id.toString() : ''}
        width='100%'
        placeHolder='이용권 선택'
        disabled={!selectedUsageTime}
      />

      <Spacing size={40} />

      <Flex align='center'>
        <Text color='gray500' typography='14_Md' style={{ margin: '0px 2px 8px 0px' }}>
          등원 시간
        </Text>
        <Text color='red600'>*</Text>
      </Flex>

      <CustomSelect
        options={getStartHourOptions()}
        onClick={selectStarHour}
        value={startHour}
        width='100%'
        placeHolder='등원 시간 선택'
        disabled={!selectedTicket}
      />

      {isTimeExceeded && (
        <Text typography='12_Rg' color='red600' style={{ marginTop: '8px' }} display='block'>
          해당 등원시간은 영업시간을 초과합니다. 계속하시려면 [등록하기]를 눌러주세요.
        </Text>
      )}
    </>
  )
}

export default SelectTimeTicket
