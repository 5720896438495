const SideBarSchedule = ({ isOn = false }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.07692 7C5.7913 7 5.51739 7.10994 5.31542 7.30562C5.11346 7.50132 5 7.76673 5 8.04348V17.9565C5 18.2333 5.11346 18.4987 5.31542 18.6944C5.51739 18.89 5.7913 19 6.07692 19H17.9231C18.2087 19 18.4826 18.89 18.6846 18.6944C18.8865 18.4987 19 18.2333 19 17.9565V8.04348C19 7.76673 18.8865 7.50132 18.6846 7.30562C18.4826 7.10994 18.2087 7 17.9231 7H16.7692'
        stroke={`${isOn ? '#272D3A' : '#C8CEDA'}`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 10.3844H19'
        stroke={`${isOn ? '#272D3A' : '#C8CEDA'}`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 5V8.23077'
        stroke={`${isOn ? '#272D3A' : '#C8CEDA'}`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 5V8.23077'
        stroke={`${isOn ? '#272D3A' : '#C8CEDA'}`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 7L14 7'
        stroke={`${isOn ? '#272D3A' : '#C8CEDA'}`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SideBarSchedule
