import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, useState } from 'react'
import * as XLSX from 'xlsx-js-style'
import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import { useProfile } from '@hooks/useProfile'
import { useCustomer } from '@hooks/useCustomer'
import { header, row, row2 } from '@constants/file'
import { QUERY_KEY } from '@constants/queryKey'
import { TAB_TITLE } from '@constants/userInfo'
import { ErrorResponseData, excelUpload } from '@apis/upload/fileUpload'
import { convertExcelToCSV } from '@utils/convertExcelToCsv'
import { MultipleUploadResponse } from '@/types/customer'
import { useAlertContext } from '@contexts/AlertContext'
import { CustomerSearchFormValues } from '@/types/form'
import Button from '@common/Button'
import Flex from '@common/Flex'
import Modal from '@common/Modal'
import Tab from '@common/Tab'
import Text from '@common/Text'
import CustomerModal from '@components/customer/CustomerModal'
import CustomerSearch from '@components/customer/CustomerSearch'
import CustomerList from '@components/customer/CustomerList'

const CustomersPage = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const [keyword, setKeyword] = useState({})
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false)
  const [showAddMultipleCustomerModal, setShowAddMultipleCustomerModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [file, setFile] = useState<File | null>(null)
  const is_active = currentTab === 0 ? true : false

  const { profile } = useProfile()
  const { open } = useAlertContext()
  const queryClient = useQueryClient()

  const { customerList, refetch } = useCustomer(
    offset,
    currentPage,
    profile?.petKindergarden?.id,
    keyword,
    is_active,
  )

  const handleTab = (id: number) => {
    setCurrentTab(id)
  }

  const openModal = () => {
    setShowAddCustomerModal(true)
  }

  const openMultipleModal = () => {
    setShowAddMultipleCustomerModal(true)
  }

  const onSearch = (search: CustomerSearchFormValues) => {
    setKeyword(search)
  }

  const handleDownload = () => {
    const ws = XLSX.utils.aoa_to_sheet([header, row, row2])

    ws['!cols'] = [{ wpx: 150 }, { wpx: 150 }, { wpx: 150 }]

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, '')

    XLSX.writeFile(wb, '멍매니저 고객 일괄 등록 템플릿.xlsx')
  }

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (file) {
      const csvFile = await convertExcelToCSV(file)

      setFile(csvFile)
    }
  }

  const { mutate: fileUpload } = useMutation<
    MultipleUploadResponse[],
    AxiosError<ErrorResponseData>,
    FormData
  >({
    mutationFn: (formData) => {
      return excelUpload(profile?.petKindergarden?.id, formData)
    },

    onSuccess: (data: MultipleUploadResponse[]) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CUSTOMER_LIST, currentPage, keyword, is_active],
      })

      setFile(null)
      setShowAddMultipleCustomerModal(false)

      open({
        title: `${data.length}명의 고객이 등록되었습니다`,
        buttonLabel: '확인',
        onComplete: () => {},
      })
    },

    onError: () => {
      open({
        title: '다시 등록해주세요',
        description: <>중복된 휴대폰번호, 번호 오류, 빈칸 등이 있을 수 있습니다.</>,
        buttonLabel: '확인',
        onComplete: () => {},
      })

      setFile(null)
    },
  })

  const onUpload = () => {
    if (!file) return
    const formData = new FormData()
    formData.append('csvFile', file)

    fileUpload(formData)
  }

  const handleReset = () => {
    setKeyword({})
    refetch()
  }

  return (
    <>
      <CustomersTabContainer>
        <CustomersTabWrapper justify='space-between'>
          <Tab tabs={TAB_TITLE} onClick={handleTab} currentTab={currentTab} />
        </CustomersTabWrapper>

        {currentTab === 0 && (
          <ButtonBox>
            <Button size='tiny' color='line' onClick={openModal}>
              <Text typography='14_Md' color='gray400'>
                고객 등록
              </Text>
            </Button>
            <Button size='tiny' color='line' onClick={openMultipleModal}>
              <Text typography='14_Md' color='gray400'>
                일괄 등록
              </Text>
            </Button>
          </ButtonBox>
        )}
      </CustomersTabContainer>

      <CustomerContainer direction='column'>
        <CustomerSearch active={currentTab} onSearch={onSearch} onReset={handleReset} />

        {customerList && (
          <>
            <CustomerCountBox>
              <Text color='gray500' typography='14_Rg'>
                총 {customerList?.count}명
              </Text>
            </CustomerCountBox>

            <CustomerList
              customerList={customerList}
              currentTab={currentTab}
              setOffset={setOffset}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        )}
      </CustomerContainer>

      {showAddCustomerModal && (
        <CustomerModal setShowModal={setShowAddCustomerModal} id={profile.petKindergarden.id} />
      )}

      {showAddMultipleCustomerModal && (
        <Modal
          title='고객 일괄 등록'
          description={
            <>
              여러 고객을 한 번에 등록할 때 사용합니다 <br />
              Exel 템플릿 파일을 다운로드하여 내용을 작성한 뒤 <br />
              업로드해주세요
            </>
          }
          body={
            <Flex gap={10} direction='column' css={{ margin: '40px 0px 0px 0px' }}>
              <Button color='line' size='large' full onClick={handleDownload}>
                <Text typography='14_Md' color='gray400'>
                  Excel 템플릿 다운로드
                </Text>
              </Button>
              <MultipleUploadContainer>
                <input type='file' onChange={onChange} accept='.csv, .xlsx, .numbers' />
                <Button color='primary' size='large' full onClick={onUpload}>
                  <Text typography='14_Md' color='white'>
                    파일 업로드
                  </Text>
                </Button>
              </MultipleUploadContainer>
            </Flex>
          }
          rightButtonLabel='등록하기'
          showModal={showAddMultipleCustomerModal}
          setShowModal={setShowAddMultipleCustomerModal}
          rightButtonOnClick={onUpload}
          disabled={!file}
          resetState={setFile}
        />
      )}
    </>
  )
}

const CustomerContainer = styled(Flex)`
  padding: 40px 80px;
`

const MultipleUploadContainer = styled(Flex)`
  position: relative;

  & > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`

const ButtonBox = styled(Flex)`
  gap: 20px;
  padding: 20px 80px 20px 0px;
`

const CustomersTabContainer = styled(Flex)`
  border-bottom: 1px solid ${colors.gray200};
`

const CustomersTabWrapper = styled(Flex)`
  flex: 1;
  margin: 50px 80px 0px 80px;
`

const CustomerCountBox = styled(Flex)`
  margin: 40px 0px 20px 0px;
`

export default CustomersPage
