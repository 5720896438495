import styled from '@emotion/styled'

import NotListImage from '@assets/images/not_list.png'
import Text from '@common/Text'
import Flex from '@common/Flex'

interface NotListProps {
  title: string
  description?: React.ReactNode
}

const NotList = ({ title, description }: NotListProps) => {
  return (
    <NotCustomer direction='column' align='center' justify='center'>
      <img src={NotListImage} alt='리스트 없는 이미지' />
      <Text typography='24_Sb' color='gray600'>
        {title}
      </Text>
      {description && (
        <Text typography='14_Rg' color='gray500' textAlign='center'>
          {description}
        </Text>
      )}
    </NotCustomer>
  )
}

const NotCustomer = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 20px;

  img {
    width: 130px;
    height: 130px;
  }
`

export default NotList
