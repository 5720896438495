// 회색 발바닥 아이콘
const SignupCompleteLogo = () => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='19.5' stroke='#C8CEDA' />
      <circle cx='20.0014' cy='20.0001' r='16.5385' stroke='#C8CEDA' strokeWidth='2' />
      <circle cx='13.4009' cy='13.7068' r='1.76223' fill='#C8CEDA' />
      <circle cx='26.3247' cy='13.7071' r='1.76223' fill='#C8CEDA' />
      <circle cx='19.8638' cy='11.9444' r='1.76223' fill='#C8CEDA' />
      <path
        // fill-rule="evenodd"
        // clip-rule="evenodd"
        d='M20.049 29.2279C18.7384 29.8173 17.3268 29.9633 16.0195 29.5409C12.8638 28.5213 11.51 24.5767 12.9956 20.7303C14.275 17.4177 17.2432 15.2583 20.0581 15.3741C22.8681 15.2676 25.827 17.425 27.1039 20.731C28.5895 24.5774 27.2357 28.522 24.08 29.5415C22.7722 29.9641 21.3601 29.8179 20.049 29.2279Z'
        fill='#C8CEDA'
      />
    </svg>
  )
}

export default SignupCompleteLogo
