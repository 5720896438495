import styled from '@emotion/styled'
import { format, subMonths, addMonths } from 'date-fns'
import { ko } from 'date-fns/locale'
import { useSetRecoilState } from 'recoil'

import { convertToSelectedDate } from '@atoms/selectedDate'
import pagingArrowL from '@assets/Paging_Arrow_L.svg'
import pagingArrowR from '@assets/Paging_Arrow_R.svg'
import Text from '@common/Text'
import Flex from '@common/Flex'
import Button from '@common/Button'
import { selectedDateState } from '@atoms/selectedDate'

interface CalendarHeaderProps {
  date: Date
  setDate: (date: Date) => void
  setCurrentMonth: (date: Date) => void
}

const CalendarHeader = ({ date, setDate, setCurrentMonth }: CalendarHeaderProps) => {
  const setSelectedDate = useSetRecoilState(selectedDateState)

  const handlePreviousMonth = () => {
    setDate(subMonths(date, 1))
    setCurrentMonth(subMonths(date, 1))
  }

  const handleNextMonth = () => {
    setDate(addMonths(date, 1))
    setCurrentMonth(addMonths(date, 1))
  }

  const handleTodayClick = () => {
    const newToday = new Date()
    setSelectedDate(convertToSelectedDate(newToday))
    setDate(newToday)
    setCurrentMonth(newToday)
  }

  return (
    <Wrapper justify='space-between' align='center'>
      <Flex align='center' gap='10px'>
        <Text typography='24_Sb'>{format(date, 'yyyy년 M월', { locale: ko })}</Text>
        <Flex gap='8px'>
          <Flex onClick={handlePreviousMonth}>
            <img src={pagingArrowL} alt='pagingArrowL' />
          </Flex>
          <Flex onClick={handleNextMonth}>
            <img src={pagingArrowR} alt='pagingArrowR' />
          </Flex>
        </Flex>
      </Flex>

      <Button
        color='line'
        onClick={handleTodayClick}
        css={{ width: 'auto', paddingLeft: '16px', paddingRight: '16px' }}
      >
        오늘
      </Button>
    </Wrapper>
  )
}

export default CalendarHeader

const Wrapper = styled(Flex)`
  padding: 31px 0 11px 0;
  flex-shrink: 0;
`
