import styled from '@emotion/styled'
import { getDate } from 'date-fns'
import { useSetRecoilState } from 'recoil'

import { convertToSelectedDate } from '@atoms/selectedDate'
import { colors } from '@styles/colors'
import holiday from '@assets/images/holiday.png'
import Text from '@common/Text'
import Flex from '@common/Flex'
import CalendarTimeIcon from '@components/icon/CalendarTimeIcon'
import CalendarDayIcon from '@components/icon/CalendarDayIcon'
import CalendarHotelIcon from '@components/icon/CalendarHotelIcon'
import { selectedDateState } from '@atoms/selectedDate'

interface DayProps {
  day: Date
  isCurrentMonth: boolean
  isSelected: (day: Date) => boolean
  isDayOff: (day: Date) => boolean
  isToday: (day: Date) => boolean
  getHolidayName: (day: Date) => string | null
  getPetCount: (day: Date, key: string) => number
}

const Day = ({
  day,
  isCurrentMonth,
  isSelected,
  isDayOff,
  isToday,
  getHolidayName,
  getPetCount,
}: DayProps) => {
  const setSelectedDate = useSetRecoilState(selectedDateState)

  const handleDayClick = (day: Date) => {
    setSelectedDate(convertToSelectedDate(day))
  }

  return (
    <Wrapper
      onClick={isCurrentMonth ? () => handleDayClick(day) : undefined}
      isSelected={isSelected(day)}
      isCurrentMonth={isCurrentMonth}
      direction='column'
      gap='8px'
      isDayOff={isDayOff(day)}
    >
      <Flex justify='space-between' align='center'>
        <Text
          typography='28_Sb'
          color={isToday(day) ? 'gray600' : isCurrentMonth ? 'gray300' : 'gray200'}
        >
          {getDate(day)}
        </Text>

        {isCurrentMonth && getHolidayName(day) && (
          <Text typography='14_Rg' color='gray400'>
            {getHolidayName(day)}
          </Text>
        )}
      </Flex>

      {isCurrentMonth && !isDayOff(day) && (
        <Flex gap='8px' css={{ flexWrap: 'wrap' }}>
          {[
            {
              count: getPetCount(day, 'timePetCount'),
              Icon: CalendarTimeIcon,
            },
            {
              count: getPetCount(day, 'allDayPetCount'),
              Icon: CalendarDayIcon,
            },
            {
              count: getPetCount(day, 'hotelPetCount'),
              Icon: CalendarHotelIcon,
            },
          ].map(
            ({ count, Icon }, idx) =>
              count > 0 && (
                <Flex key={idx} justify='center' align='center' gap='2px'>
                  <Icon isActive={isSelected(day)} size={16} />
                  <Text typography='14_Rg' color={isSelected(day) ? 'gray500' : 'gray300'}>
                    {count}
                  </Text>
                </Flex>
              ),
          )}
        </Flex>
      )}

      {isCurrentMonth && isDayOff(day) && (
        <Text typography='12_Rg' color='red600'>
          휴무
        </Text>
      )}
    </Wrapper>
  )
}

export default Day

const Wrapper = styled(Flex)<{
  isSelected: boolean
  isCurrentMonth: boolean
  isDayOff: boolean
}>`
  min-width: 140px;
  min-height: 100px;
  padding: 14px;
  cursor: ${({ isCurrentMonth }) => (isCurrentMonth ? 'pointer' : 'default')};
  background-color: ${({ isSelected }) => (isSelected ? `${colors.blue50}` : 'white')};
  border-right: 1px solid ${colors.gray200};
  border-bottom: 1px solid ${colors.gray200};
  box-sizing: border-box;
  background-image: ${({ isDayOff }) => (isDayOff ? `url(${holiday})` : 'none')};
  background-size: cover;
  background-position: center;
  ${({ isSelected }) =>
    isSelected &&
    `
    border: 1px solid ${colors.blue600};
    box-shadow: 0px 4px 15px 0px #272D3A26;
    z-index: 1; 
  `}
  &:last-of-type {
    ${({ isSelected }) => !isSelected && 'border-right: none;'}
  }
  &:nth-of-type(7n) {
    ${({ isSelected }) => !isSelected && 'border-right: none;'}
  }
  &:nth-last-of-type(-n + 7) {
    ${({ isSelected }) => !isSelected && 'border-bottom: none;'}
  }
`
